export default {
    bind(el,binding){
        /**
         * position - middle/left/right
         * eg:v-ellipsis-text:middle="{start:2,end:5}"  省略符号在中间,省略符前保留2个字符,省略符后面保留5个字符,如果不设置,那么默认
         */
        let position = binding.arg?binding.arg:'middle';
        let start = binding.value&&binding.value.start?binding.value.start:5;
        let end = binding.value&&binding.value.end?binding.value.end:10;
        let text = el.innerText;
        el.title = text;
        if(text.length>(start+end+5)){
            switch (position) {
                case 'middle':
                    el.innerText = text.substring(0,start)+'.....'+ text.substring(text.length-end,text.length)
                    break;
                case 'left':
                    el.innerText = '.....'+ text.substring(text.length-end,text.length)
                    break;
                case 'right':
                    el.innerText = text.substring(0,start)+'.....'
                    break;
            }
        }
    }
}