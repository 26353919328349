export const compare = (comparison, target, value) => {
  const [min, max] = target;
  if (comparison == "between") {
    if (min < value && value < max) {
      return true;
    }
  }
  if (comparison == "<") {
    if (value < min) {
      return true;
    }
  }
  if (comparison == ">") {
    if (value > min) {
      return true;
    }
  }
  if (comparison == "<=") {
    if (value <= min) {
      return true;
    }
  }
  if (comparison == ">=") {
    if (value >= min) {
      return true;
    }
  }
  if (comparison == "==") {
    if (value === min) {
      return true;
    }
  }
};

function formatNumber(n) {
  n = n.toString()
  return n[1] ? n : '0' + n
}

export const formatTime = (longTime,format) => {
  if(!longTime) return ''
  let formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
  let returnArr = [];
  let date = new Date(longTime);
  returnArr.push(date.getFullYear());
  returnArr.push(formatNumber(date.getMonth() + 1));
  returnArr.push(formatNumber(date.getDate()));
  returnArr.push(formatNumber(date.getHours()));
  returnArr.push(formatNumber(date.getMinutes()));
  returnArr.push(formatNumber(date.getSeconds()));
  for (let i in returnArr) {
    format = format.replace(formateArr[i], returnArr[i]);
  }
  return format;
}

export const getValueByForm = (item)=>{
  if (['multiMonthChildren', 'scheduleChildren'].includes(item.myChildrenType)) {
    if (['activityType','activitySchedule','cardType'].includes(item.myName)) {
      return item.value && item.value.name;
    }
    if (['activityNames'].includes(item.myName)) {
      return Array.isArray(item.value) ? item.value.map(ele => ele.name).join(',') : item.value;
    }
    if (['months'].includes(item.myName)) {
      return Array.isArray(item.value) ? item.value.join() : item.value
    }
  }
  if (["multipleSelector", "singleSelector"].includes(item.type) && !['multiMonthChildren', 'scheduleChildren'].includes(item.myChildrenType)) {
    return (item.options.filter((e) => e.id == item.value||(item.value||[]).includes(e.id)) || {}).reduce((res,e)=>{res.push(e.value);return res},[]).join(',');
  }
  if (item.type=='cascader') {
    return item.value?(item.value.constructor==Array?item.value.reduce((res,e)=>{res.push(e.name);return res},[]).join(','):item.value.name):''
  }
  if (item.type == "area") {
    return item.value&&item.value.name;
  }
  if (item.type == "computed") {
    return item.computed_value||'';
  }
  if(item.type == "dateTime"){
    return formatTime(item.value, item.props.format=='yyyy-MM-dd'?"Y-M-D": (item.props.format=='yyyy-MM' ? "Y-M" : "Y-M-D h:m"))

  }
  return item.value;
}

export const createMonthsOptions = () => {
  let date = new Date()
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let options = []
  for (let i = month; i <= 12; i++) {
    let m = String(i).length === 1 ? ('0' + i) : i
    let item = (year - 1) + '-' + m
    options.push(item)
  }
  for (let j = 1; j < month; j++) {
    let m = String(j).length === 1 ? ('0' + j) : j
    let item = (year) + '-' + m
    options.push(item)
  }
  return options || []
}