<template>
  <div class="dynamic-form-list">
    <dynamic-form-search
      v-model="searchParams"
      @search="search"
      @export="_export"
      :hasBPM="hasBpm"
    ></dynamic-form-search>
    <div class="container">
      <div>
        <el-button
          @click="add"
          size="medium"
          type="primary"
          style="margin-left: 16px; margin-bottom: 16px"
          >新增</el-button
        >
        <el-table :data="tableData" class="contentTable" size="medium" v-if="tableData">
          <el-table-column
            :prop="formItem.key"
            :label="formItem.label"
            v-for="(formItem, index) of headers"
            :width="(formItem.key.includes('image')||formItem.key.includes('attachment'))?200:'auto'"
            :key="index"
          >
            <template v-slot="{ row }" v-if="formItem.key.includes('image')">
              <div class="upload-img">
                <el-image @click.stop="handleClickStop" v-for="img in row[formItem.key]" :key="img.url" style="width: 25px; height: 25px;margin-right: 5px" :src="img.url" :preview-src-list="[img.url]"></el-image>
              </div>
            </template>
            <template v-slot="{ row }" v-else-if="formItem.key.includes('attachment')">
              <list-file :files="row[formItem.key]"></list-file>
            </template>
            <template v-slot="{ row }" v-else>
              {{ getValue(row, formItem.key) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="150">
            <!-- 0-进行中，1-已完成，2-已驳回，3-已撤销，4-驳回待修改，5-终止 -->
            <template slot-scope="scope">
              <span @click="see(scope.row)" class="blue mg-r">查看</span>
              <span
                @click="edit(scope.row)"
                class="blue mg-r"
                v-if="$reconava.isSelf_phone(scope.row.applyUserMobile)&&(!scope.row.hasBpm||[2].includes(+scope.row.status))"
                >编辑</span
              >
              <span
                @click="stop(scope.row)"
                class="blue mg-r"
                v-if="[2].includes(+scope.row.status)&&$reconava.isSelf_phone(scope.row.applyUserMobile)"
                >终止</span
              >
              <span
                @click="withdraw(scope.row)"
                class="blue mg-r"
                v-if="[0].includes(+scope.row.status) && $reconava.isSelf_phone(scope.row.applyUserMobile) && scope.row.hasRevoke"
                >撤销</span
              >
              <!--     审批通过后，可以打印详情           -->
              <span class="mgr10 blue" v-if="scope.row.status==1&&scope.row.hasBpm" @click="_print(scope.row)">打印</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <reconova-pagination
        @paginationChange="paginationChange"
        :page-no="page.num"
        :page-size="page.size"
        :total="page.total"
      ></reconova-pagination>
    </div>
  </div>
</template>

<script>
import DynamicFormSearch from "./components/dynamicFormSearch.vue";
import reconovaPagination from "@/components/reconova/reconovaPagination";
import { operatorDialog } from "@/utils/utils";
import ListFile from "@/components/list-file";
import {formatTime} from "./utils/utils";
import {flatten} from "@/components/dynamicForm/utils/utils";
import {onDownload} from "@/utils/utils";
import {getPrefixUrl, getToken} from "../../../services/fetch";
import {createMonthsOptions} from "./utils/utils"
export default {
  name: "DynamicFormList",
  components: {ListFile, DynamicFormSearch, reconovaPagination },
  data() {
    return {
      headers: [], // 表头
      tableData: null, // 列表数据
      formSetting: [], // 表单数据
      hasBpm:false,
      searchParams: {
        tableSearch: [],
        status: "",
        processNumber: '',
        createTime: [],
      }, // 查询参数
      tableShow: [], // 列表项的显隐，貌似用不上？
      page: {
        // 分页
        num: 1,
        size: 10,
        total: 0,
      },
    };
  },
  created() {
    this.getForm();
    this.search();
  },
  methods: {
    handleClickStop() {
      // 点击遮罩层时调用关闭按钮的 click 事件
        this.$nextTick(() => {
          let domImageView = document.querySelector(".el-image-viewer__mask"); // 获取遮罩层dom
          if (!domImageView) {
            return;
          }
          domImageView.addEventListener("click", () => {
            // 点击遮罩层时调用关闭按钮的 click 事件
            let res = document.getElementsByClassName('el-image-viewer__close')
            for(let i in res){
              try {
                res[i].click()
              } catch (error) {
                
              }
            }
            // document.querySelector(".el-image-viewer__close").click();
          });
        });
      },
    getValue(item, key) {
      const value = item[key];
      if(!value) return '';
      if (key.includes('singleSelector') && (key.includes('activityType') || key.includes('activitySchedule') || key.includes('cardType'))) {
        return value.name || ''
      }
      if (key.includes('dateMonths')) {
        return Array.isArray(value) ? value.join() : value
      }
      if (key.includes('multipleSelector') && key.includes('activityNames')) {
        return value.map(ele => ele.name).join()
      }
      if (key.includes('cascader')||(key.includes('area')&&!key.includes('textarea'))) {
        return value.constructor==Array?value.reduce((res,e)=>{res.push(e.name);return res},[]).join(','):value.name
      }else if(key.includes('Selector')){
        const targetItem = flatten(Object.values(this.formSetting)).filter(item=>item.id==key)[0]
        return (targetItem||'')&&targetItem.options.reduce((res,el)=>{
          if(el.id==value||value.includes(el.id)){
            res.push(el.value)
          }
          return res
        },[]).join(',')
      }else if(key.includes('dateTime')){
        const targetItem = flatten(Object.values(this.formSetting)).filter(item=>item.id==key)[0]
        return targetItem && formatTime(value, targetItem.props.format=='yyyy-MM-dd'?"Y-M-D":"Y-M-D h:m")
      }
      return value;
    },

    getId(processId) {
      return new Promise((res, rej) => {
        this.$api.dynamicForm
          .getId(processId)
          .then(({ code, data }) => {
            if (code == 200) {
              const { id } = data;
              res(id);
            } else {
              rej();
            }
          })
          .catch(rej);
      });
    },
    edit(item) {
      // 不参与流程的数据需要可以编辑
      if(item.hasBpm){
        this.getId(item.processId).then((id) => {
          this.$router.push({
            path: `/businessModule/dynamicForm/${this.$route.params.groupId}/${this.$route.params.formId}/edit/${id}`,
            query: { approveDataId: item.approveDataId }
          });
        });
      }else{
        this.$router.push({
          path:`/businessModule/dynamicForm/${this.$route.params.groupId}/${this.$route.params.formId}/edit/${item.processId}/${item.processId}`,
          query: { approveDataId: item.approveDataId }
        })
      }

    },
    see(item) {
      //  区分有流程数据和无流程数据
      if(item.hasBpm){
        this.getId(item.processId).then((id) => {
          this.$router.push({
            path: `/businessModule/dynamicForm/${this.$route.params.groupId}/${this.$route.params.formId}/detail/${id}`,
          })
        })
      }else{
        this.$router.push({
          path: `/businessModule/dynamicForm/${this.$route.params.groupId}/${this.$route.params.formId}/noProcessDetail/${item.processId}`,
        })
      }
    },
    async stop(item) {
      const id = await this.getId(item.processId)
      operatorDialog(
        this,
        "理由",
        "终止",
        (value) => {
          let param = {
            id,
            typeId: 9,
            reason: value || "",
          };
          this.$api.checkapi.terminateMyApply(param).then((response) => {
            this.$message.success("已终止");
            this.search();
          });
        },
        {
          placeholder: item.approveOpinion.cancel.msg || "",
          required: item.approveOpinion.cancel.required,
        }
      );
    },
    async withdraw(item) {
      const id = await this.getId(item.processId)
      operatorDialog(
        this,
        "理由",
        "撤销",
        (value) => {
          let param = {
            id,
            typeId: 9,
            reason: value || "",
          };
          this.$api.checkapi.repealMyApply(param).then((response) => {
            this.$message.success("已撤销");
            this.search();
          });
        },
        {
          placeholder: item.approveOpinion.cancel.msg || "",
          required: item.approveOpinion.cancel.required,
        }
      );
    },
    add() {
      this.$router.push({
        path: `/businessModule/dynamicForm/${this.$route.params.groupId}/${this.$route.params.formId}/edit`,
      });
    },
    getForm() {
      this.$api.dynamicForm.externalForm(this.$route.params.formId).then(
        ({
          data: {
            basic: {hasBpm},
            form: { tableSearch, tableShow ,formSetting},
          },
        }) => {
          tableSearch.forEach(item => {
            if (item.searchCode ===  'cardType') {
              // 卡类型设置选项
              this.setCardOption(item, 'componentCardType')
            }
            if (item.searchCode ===  'activityType') {
              this.setCardOption(item, 'componentEmaClassify')
            }
            if (item.searchCode ===  'activitySchedule') {
              this.setCardOption(item, 'componentEmaSchedule')
            }
            if (item.searchCode ===  'activityNames') {
              this.setCardOption(item, 'componentEmaSelect')
            }
            if (item.searchCode ===  'months') {
              this.setMonthsOption(item)
            }
          })
          this.searchParams.tableSearch = tableSearch;
          this.tableShow = tableShow;
          this.hasBpm = hasBpm;
          this.formSetting = formSetting;
        }
      );
    },
    setCardOption(item, key){
      // 卡类型设置选项
      const userInfo = JSON.parse(localStorage.userInfo)
      this.$api.dynamicForm[key]({marketCode:userInfo.marketCode}).then(res=>{
        if(res.code==200){
          item.options=[]
          res.data.forEach(t=>{
            item.options.push({
              value: t.text,
              id: t.value,
              relations: [] // 关联关系
            })
          })
        }else{
          item.options=[]
        }
      }).catch(e=>{item.options=[]})
    }, 
    setMonthsOption(item, key){
      item.options= createMonthsOptions().map(ele => {
        return {
          value: ele,
          id: ele,
          relations: [] // 关联关系
        }
      })
    },
    paginationChange(type, value) {
      if (type === "pageSize") {
        this.page.num = 1;
        this.page.size = value;
      } else {
        this.page.num = value;
      }
      this.search(this.page.num);
    },

    getParam(pageNum){
     const code =  JSON.parse(localStorage.getItem("dynamicFormMenu"))
         .find((e) => e.id == this.$route.params.groupId)
         .basics.find((e) => e.id == this.$route.params.formId).code;
     const data = {
       customParam: this.searchParams.tableSearch.map((e) => ({
         [e.id]: e.id.includes('dateTime')?  this.timeFormat(e.value) : e.value,
       })),
       pageNum: pageNum||1,
       pageSize: this.page.size,
       status: this.searchParams.status,
       processNumber: this.searchParams.processNumber,
     }
     if (this.searchParams.createTime.length) {
        data.createTimeStart = this.searchParams.createTime[0]
        data.createTimeEnd = this.searchParams.createTime[1]
      }
     // return {code:'1337d207afbe479b8bb255acab8aecc7',data}
      return {code,data}
    },

    search(pageNum) {
      const {code,data} = this.getParam(pageNum)
      this.$api.dynamicForm
        .externalList(code,data)
        .then(({ data,msg,code }) => {
          if(code == 450){
            return this.$message.error(msg)
          }
          if (data) {
            const {
              records: [{ headers, tableData }],
              total,
            } = data;
            this.headers = headers;
            this.tableData = tableData;
            this.page.total = total;
          }
        });
    },

    timeFormat(value){
      if(!value) return value;
      let result = ""
      try {
        let time = value.split(" ")[1]||"00:00:00";
        let date = value.split(" ")[0]
        if(time){
          let _time = time.split(':')
          let hour = _time[0]?_time[0].padStart(2,'0'):'00';
          let min  =  _time[1]?_time[1].padStart(2,'0'):'00';
          let sec  =  _time[2]?_time[2].padStart(2,'0'):'00';
          time = `${hour}:${min}:${sec}`
        }
        value = date + " " + time
        result = new Date(value).getTime()||value
      }catch(e){
        result = value
      }
      return result
    },

    _export(){
      const url = getPrefixUrl();
      const {code,data} = this.getParam()
      this.$axios.post(`${url}/business/approve/external/list/export/${code}`,data,{
        headers:{
          'token':getToken(),
        },
        responseType:'blob'
      }).then(({headers, data})=>{
        onDownload(headers, data);
      })
    },

    _print(item){
      this.getId(item.processId).then((id) => {
        this.$router.push({path:'/businessModule/dynamicForm/print',query:{id}})
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.dynamic-form-list {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .container {
    background: white;
    margin-top: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .contentTable{
      height: calc(100vh - 420px);
      overflow-y: auto;
    }
  }
  .mg-r {
    margin-right: 4px;
  }
}
</style>